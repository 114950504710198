<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="$toPath('/web/sjz/jg/classList')">班级管理</a>
          <i>></i>
          <a
            href="javascript:;"
            @click="$router.push({path:'/web/sjz/jg/RollCallSet',query:{projectId:projectId}})"
          >点名设置</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">点名设置预览</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="operationControl">
              <h2>课程名称：{{courseName}}</h2>
            </div>
            <templete v-if="open">
              <div v-for="(el,index) in groups" :key="index">
                <div class="table_item">
                  <div class="df" style="align-items:flex-end;font-size:16px;padding:5px 0;">
                    <h3>{{el.eduRollGroup.groupName}}</h3>
                    <span>（共{{el.eduRollGroup.userNum}}名学员）</span>
                  </div>
                  <RollCallPage
                    ref="RollCallPage"
                    :ShowStudentList="true"
                    :Height="groups.length"
                    :groupItem="el"
                    :sliderDisabled="true"
                    v-if="open"
                  ></RollCallPage>
                </div>
              </div>
            </templete>
          </div>
        </div>
        <div class="flexjc">
          <el-button class="bgc-bv" round @click="docencal">取消</el-button>
          <el-button class="bgc-bv" round @click="dosave">确定</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RollCallPage from "@/components/Rollcallpage.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "sjz/jg/rollCallView",
  components: {
    RollCallPage
  },
  mixins: [List],
  data() {
    return {
      groupName: this.$route.query.groupName,
      userNum: this.$route.query.userNum,
      courseName: "",
      open: false,
      groups: [], // 分组
      projectId: this.$route.query.projectId,
      oldlength: 0, // 定时用
      datalength: 2,
      intervavl:null
    };
  },
  computed: {},
  created() {
    this.getlist();
  },
  methods: {
    // 预览列表
    getlist() {
      this.$post("/biz/roll/group/projectRollCallView", {
        projectId: this.projectId
      }).then(res => {
        if (res.status == 0) {
          const data = res.data || {};
          this.open = true;
          this.courseName = data.courseName;
          if (data.groups.length >= 2) {
            this.intervavl = setInterval(() => {
              this.handleData(data.groups);
            }, 200);
          } else {
            this.groups = data.groups;
          }
        }
      });
    },
    handleData(data) {
      if (this.datalength <= data.length) {
        let list = [];
        let newlength = this.datalength + 1;
        list = data.slice(this.oldlength, this.datalength);
        this.groups = [...this.groups,...list]
        this.oldlength = newlength-1;
        this.datalength = newlength-1;
        this.datalength ++;
        if(newlength>data.length){
          this.datalength = 2;
          this.oldlength = 0;
          clearInterval(this.intervavl);
        }
      }
    },
    // 确定
    dosave() {

      this.$post("/biz/project/affirm", {
        projectId: this.projectId
      }).then(res => {
        // ;
        if (res.status == 0) {
          this.$message({
            type: "success",
            message: "开班申请提交成功"
          });
          this.$router.push({
            name: "sjz/jg/classList",
            params: {
              // refresh: true,
            }
          });
          sessionStorage.setItem("refresh", 10);
        } else {
          this.$message({
            type: "error",
            message: res.message
          });
        }
      });
    },
    // 取消
    docencal() {
      this.$router.back();
    }
  }
};
</script>
<style lang="less">
</style>
<style lang="less" scoped>
.studentnum {
  padding-left: 20px;
  font-size: 14px;
}
</style>

